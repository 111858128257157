.modal__container-enter {
    opacity: 0;
    transform: scale(0.9) translateY(-40px);
}
.modal__container-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity 300ms, transform 300ms;
}
.modal__container-exit {
    opacity: 1;
}
.modal__container-exit-active {
    opacity: 0;
    transform: scale(0.9) translateY(-40px);
    transition: opacity 300ms, transform 300ms;
}
.modal__backdrop {
    background-color: rgba(255,255,255,0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.modal__backdrop-enter {
    opacity: 0;
}
.modal__backdrop-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
.modal__backdrop-exit {
    opacity: 1;
}
.modal__backdrop-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}
.modal__outer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
}
.modal__frame {
    background-color: var(--yellowLighter);
    width: 100%;
    max-width: 40rem;
    box-shadow: 0 3px 7px rgba(0,0,0,0.1);
    border: 3px solid var(--purple);
    border-bottom-width: 5px;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
}
.modal__frame-inner {
    width: 100%;
    border-style: solid;
    border-width: 2px 0;
    border-top-color: var(--yellowLightest);
    border-bottom-color: var(--yellow);
    background-color: var(--yellowLighter);
    padding: 6px 10px;
    height: 100%;
}
.modal__inner {
    background-color: var(--yellowLight);
    overflow-y: auto;
    width: 100%;
    position: relative;
    z-index: 10;
    padding: 2.5rem 1.25rem 1.25rem;
    border-width: 3.5px;
    border-style: solid;
    border-top-color: var(--yellow);
    border-left-color: var(--yellow);
    border-bottom-color: var(--yellowLightest);
    border-right-color: var(--yellowLightest);
    max-height: 100%;
    height: 100%;
}
@media (max-width: 450px) {
    .modal__inner {
        padding: 5px;
    }
}
.modal__inner::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.modal__inner::-webkit-scrollbar-track {
    background-color: transparent;
}
.modal__inner::-webkit-scrollbar-thumb {
    background-color: var(--yellow);
}
.modal__closeBtn {
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    color: rgb(156,163,175);
    transition: color 150ms;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}
.modal__closeBtn:hover {
    color: rgb(75,85,99);
}
.modal__closeBtn svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: currentColor;
}