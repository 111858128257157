.gameover__title {
    color: rgb(0,0,50);
    text-align: center;
}
@media (max-width: 500px) {
    .gameover__title {
        font-size: 20px;
    }
}
@media (max-width: 420px) {
    .gameover__title {
        font-size: 16px;
    }
}
.gameover__clhLogo {
    display: block;
    width: 80%;
    margin: 50px auto;
    animation: fadeIn 1000ms 500ms ease-out forwards;
    opacity: 0;
}
@keyframes fadeIn {
    from { opacity: 0;}
    to { opacity: 1;}
}
@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    15%, 70% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    90% {
        opacity: 0;
        transform: translate(-50%, -500%);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}
.gameover__rewardpopup--animate {
    animation: fadeInOut 3000ms ease-in-out;
}
.gameover__headerBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 530px) {
    .gameover__headerBtns {
        display: block;
    }
    .gameover__headerBtns .gameover__redBtn {
        width: 100%;
        font-size: 16px;
    }
    .gameover__headerBtns .gameover__redBtn-inner {
        justify-content: center;
    }
    .gameover__vaultBtn, .gameover__timer {
        margin: 0 auto 5px;
    }
}
.gameover__redBtn {
    background-color: var(--red);
    color: white;
    font-size: 18px;
    cursor: pointer;
    display: block;
    transition: background-color 200ms;
    border: 3px solid var(--purple);
    border-bottom-width: 5px;
    padding: 0;
}
.gameover__redBtn:hover {
    background-color: var(--redDarker);
}
.gameover__redBtn-inner {
    border: 2px solid var(--yellowLightest);
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 48px;
}
.gameover__shareBtn {
    margin: 0 auto;
    font-size: 28px;
}
.gameover__shareBtn .gameover__redBtn-inner {
    height: 56px;
}
.gameover__shareBtn svg {
    margin-left: 8px;
    width: 25px;
    height: 25px;
    fill: currentColor;
}
@media (max-width: 530px) {
    .gameover__shareBtn {
        font-size: 24px;
    }
}
@media (max-width: 420px) {
    .gameover__shareBtn {
        font-size: 20px;
    }
    .gameover__shareBtn svg {
        width: 20px;
        height: 20px;
    }
}
.gameover__vaultBtn svg {
    margin-right: 3px;
    fill: currentColor;
    width: 18px;
    height: 18px;
}
.gameover__timer svg {
    margin-right: 5px;
    fill: currentColor;
    width: 16px;
    height: 16px;
}