.wheel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    min-height: 200px;
    max-width: 500px;
    max-height: 450px;
    overflow: hidden;
    margin: auto;
    --startRotationDegrees: 0deg;
    --endRotationDegrees: 360deg;
    --finalRotationDegrees: 1440deg;
}
.wheel__innerContainer {
    position: relative;
    object-fit: contain;
    flex-shrink: 0;
    z-index: 5;
    pointer-events: none;
    width: min(75vw, 445px);
    height: min(75vw, 445px);
}
.wheel__rotation {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(var(--startRotationDegrees));
}
.wheel__img {
    -webkit-user-drag: none;
    position: absolute;
    z-index: 5;
    width: 17%;
    right: 12px;
    top: 13px;
    transform: rotate(-135deg);
}
@media (max-width: 480px) {
    .wheel__img {
        right: 10px;
        top: 10px;
    }
}
@keyframes wheel-spin {
    from {
        transform: rotate(var(--startRotationDegrees));
    }
    to {
        transform: rotate(var(--endRotationDegrees));
    }
}
@keyframes wheel-continueSpin {
    from {
        transform: rotate(var(--startRotationDegrees));
    }
    to {
        transform: rotate(var(--endRotationDegrees));
    }
}
@keyframes wheel-stopSpin {
    from {
        transform: rotate(var(--startRotationDegrees));
    }
    to {
        transform: rotate(var(--finalRotationDegrees));
    }
}

.wheel__rotation--anim {
    animation: wheel-spin 2.6s cubic-bezier(0.71, -0.29, 0.96, 0.9) 0s 1 normal forwards running, wheel-continueSpin 0.75s linear 2.6s 1 normal forwards running, wheel-stopSpin 8s cubic-bezier(0, 0, 0.35, 1.02) 3.35s 1 normal forwards running;
}
.wheel__canvas {
    width: 98%;
    height: 98%;

    object-fit: contain;
}
.wheel__btn {
    background-color: rgb(0,0,50);
    color: white;
    border-radius: 100%;
    width: min(10vw, 60px);
    height: min(10vw, 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: calc(50% - min(10vw, 60px));
    right: 0;
    bottom: 0;
    transform: translate(-50%, 50%);
    z-index: 100;
    cursor: pointer;
    outline: none;
    border: 0;
    font-size: 14px;
}
@media (max-width: 450px) {
    .wheel__btn {
        font-size: 10px;
    }
}
.wheel__btn:disabled {
    cursor: default;
}