body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
    height: 100%;
    overflow: hidden;
}

.score-hud {
    position: absolute;
    top: 5%;
    font-size: 40px;
    font-weight: bold;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Flappy', sans-serif;
}

.hud-subtitle {
    position: absolute;
    top: 90px;
    font-size: 32px;
    font-weight: bold;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}
@media (max-width: 800px) {
    .hud-subtitle {
        font-size: 24px;
    }
}
@media (max-width: 500px) {
   .hud-subtitle {
       font-size: 20px;
   }
}
.game-outer-container {
    width: 100vw;
    height: 100vh;
    max-height: 900px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0,0,5);
    position: relative;
}

.game-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.transparent-hud {
    position: absolute;
    background-color: rgba(0,0,5,0.15);
    color: white;
    top: 0;
    min-width: 10px;
    padding: 6px;
    font-weight: 500;
    font-size: 18px;
}

* {
    box-sizing: border-box;
}

.toaster-container {
    width: 180px;
    max-width: 180px;
    transform: translateX(-50%) !important;
    left: 50% !important;
}
.toaster-toast {
    color: white !important;
    background-color: rgb(75,85,99) !important;
    text-align: center;
    margin-bottom: 1rem !important;
    border-radius: 4px !important;
    left: 0 !important;
}

@font-face {
    font-family: 'Flappy';
    src: url('./assets/flappy-font.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

:root {
    --yellow: #f9b733;
    --yellowLight: rgb(235, 203, 144);
    --yellowLighter: #f5d59a;
    --yellowLightest: #f5e1ca;
    --purple: #533846;
    --red: #f04922;
    --redDarker: #de3b14;
}